<template>
  <div>
    <dropdownlist
      :disabled="isUserPM"
      :name="'subscription-popup'"
      :style="{ width: '100%' }"
      :data-items="subscriptionValuesList"
      :value="value"
      @open="onOpen"
      @close="onClose"
      :text-field="'text'"
      :value-field="'value'"
      @change="handleChange"
      size="small"
      :fill-mode="'outline'"
      :filterable="false"
    >
    </dropdownlist>
    <template>
      <SubscribedDialog
        :showDialog="showDialog"
        :sga-id="sgaId"
        :legal-entity-name="legalEntityName"
        :request-id="requestId"
        @update-value="handleValueUpdate"
        @reset-subscription="onResetSubscription"
        :declined-reasons="declineReasons"
      />
    </template>
  </div>
</template>
<script>
import { DropDownList } from '@progress/kendo-vue-dropdowns'
import SubscribedDialog from './SubscribedDialog.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    dropdownlist: DropDownList,
    SubscribedDialog
  },
  props: {
    sgaId: {
      type: String,
      default: () => ''
    },
    legalEntityName: {
      type: String,
      default: () => ''
    },
    requestId: {
      type: String,
      default: () => ''
    },
    declineReasons: {
      type: Array,
      default: () => []
    },
    isSubscribed: {
      type: Boolean
    },
    isUserPM: {
      type: Boolean
    }
  },
  data: function () {
    return {
      events: [],
      value: this.isSubscribed,
      subscriptionValuesList: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      showDialog: false,
      declineReasonsList: this.declineReasons
    }
  },
  watch: {
    isSubscribed: {
      handler() {
        this.value =
          this.isSubscribed !== null
            ? this.subscriptionValuesList.find(
                (item) => item.value === this.isSubscribed
              )
            : null
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('pmDashboard', ['updateSubscription', 'getpmDashboardData']),
    onOpen() {
      this.log('open')
    },
    onClose() {
      this.log('close')
    },
    async handleChange(event) {
      if (event !== null) {
        const eventValue = event.target.value

        this.showDialog = eventValue.value === false

        if (eventValue.value === true) {
          const payload = {
            sgaId: this.sgaId,
            isSubscribed: true,
            declineId: null
          }
          const responseStatus = await this.updateSubscription(payload)
          if (responseStatus && responseStatus === 200) {
            this.value = event.target.value
            this.$emit('getDatawithFilter', false)
          }
        }
      }
    },
    handleValueUpdate(event) {
      this.value = event
    },
    onResetSubscription() {
      this.value =
        this.isSubscribed !== null
          ? this.subscriptionValuesList.find(
              (item) => item.value === this.isSubscribed
            )
          : null
      this.showDialog = false
    },
    log(event, arg) {
      const events = this.events
      events.unshift(`${event} ${arg || ''}`)
    }
  }
}
</script>
